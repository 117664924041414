.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	select {
		-webkit-appearance: none;

		option {
			color: var(--font-color-main);
			font-size: 15px;
		}
	}

	.icon-field {
		.form-control {
			padding-left: 15px !important;
		}

		.fa.fa-angle-down {
			position: absolute;
			right: 15px;
			left: unset;
			top: 12px;
		}
	}
}