.vo-search-body {
	.navbar-v1.mod-affix.affix-top {
		&:before {
			height: 200px;
		}
	}
}

.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	.search-banner {
		background-image: url(RESOURCE/img/search-banner-1.jpg);
		height: 250px;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 0 60%;
		margin-bottom: 40px;
	}

	.container-xl {
		.row {
			justify-content: space-between;

			.col-xl-8 {
				@media (min-width:1500px) {
					-webkit-box-flex: 0;
					flex: 0 0 59.666667%;
					max-width: 59.666667%;
				}
			}
		}

	}



	.more-btn-panel {
		margin-top: 30px;
	}


}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}