.searchbar-v1 {
	position: absolute;
	top: 75vh;
	width: 100%;

	@media (max-width: 767px) {
		padding: 0 10px;
		bottom: -20px;
		position: relative;
		top: unset;
		margin-bottom: 30px;
	}

	@media all and (min-width: 768px) and (max-width: 992px) {
		position: static;
		margin: 20px 0;
	}

	@media all and (min-width: 993px) and (max-width: 1199px) {
		top: 400px;
	}

	@media all and (min-width: 1200px) and (max-width: 1499px) {
		top: 500px;
	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 0px solid #eaeaea;
		flex-flow: wrap;
		border-radius: 5px;


		@media (max-width: 992px) {
			box-shadow: 0 2px 12px rgb(0 0 0/20%);
		}

		.form-flex {
			width: 30%;
			padding: 10px 22px;
			border-right: 2px solid #847E7A;
			margin: 10px 0;

			&:last-child {
				border-right: none;
			}



			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				top: 6px;
				font-size: 20px;
			}

			.form-control {
				padding: 5px 0;
				background-color: var(--white);
				border: none;
				text-align: left;
				box-shadow: inherit;
				background-color: var(--color-white);
				cursor: pointer;
				padding-left: 35px;

				option {
					color: var(--font-color-main);
					font-size: 15px;
				}

			}

			>label {

				color: var(--black);
				font-size: 14px;
				text-transform: uppercase;
				display: block;
				font-weight: 600;
				letter-spacing: 2px;
				font-family: var(--title-font-family-main);

				margin: 0;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;

				>.fa {
					position: absolute;
					right: 0;
					top: 0;
					left: inherit;
					width: 30px;
					background-color: #fff;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					pointer-events: none;
					margin: 0;
				}
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 0;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {

				height: 94px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 42px;
				color: var(--color-secondary);
				margin: 0 0 0 -5px;
				border-radius: 0 5px 5px 0;
				border: none;
				background-size: 46px;

				@media (max-width:992px) {
					margin: 0;
					background-size: 26px;
				}

				&:hover {
					color: var(--color-primary);
					background-image: url(RESOURCE/img/icon-search2-white.svg);
				}

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
					border-radius: 5px !important;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}