.unit-quickbooker-v1 {

	margin-top: 60px;
	border-top: 1px solid var(--color-grey-light);
	padding-top: 40px;

	h2 {
		font-size: 1.1rem;
		font-weight: 400;
		font-weight: var(--h4-font-weight);
		color: var(--color-grey-dark);
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	.booking-hint {
		font-size: 13px;
		padding-bottom: 10px;
		margin-top: -10px;
	}

	.price-table {
		font-size: 0.88rem;
	}


	.searchbox input::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: rgb(68 68 68);
	}

	.searchbox input::-moz-placeholder {
		/* Firefox 19+ */
		color: rgb(68 68 68);
	}

	.searchbox input:-ms-input-placeholder {
		/* IE 10+ */
		color: rgb(68 68 68);
	}

	.searchbox input:-moz-placeholder {
		/* Firefox 18- */
		color: rgb(68 68 68);
	}

	.unit-img {
		position: relative;

		.unit-code {
			position: absolute;
			left: 0px;
			bottom: 0px;
			background-color: var(--color-primary);
			padding: 2px 5px;
			color: var(--color-white);
			font-size: var(--font-size-md);
		}

		.fav-icon {
			position: absolute;
			right: 10px;
			top: -10px;
			color: #fff;
			cursor: pointer;
			background-color: var(--color-secondary);
			padding: 8px 10px;
		}
	}

	.info {
		padding: 20px 15px;
	}

	.search-bl {
		position: relative;

		>.fa {
			position: absolute;
			right: 15px;
			top: 50px;
			margin: 0;
		}
	}

	.fa.fa-angle-down {
		right: 0;
		left: unset;
	}

	.form-control {
		text-align: left;
		margin-bottom: 15px;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}
}