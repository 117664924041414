@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* parisienne-regular - latin-ext_latin */
@font-face {
	font-family: 'Parisienne';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* Jost medium - latin-ext_latin */
@font-face {
	font-family: 'Jost';
	font-style: Regular;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/Jost-Medium.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-Medium.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Jost Regular - latin-ext_latin */
@font-face {
	font-family: 'Jost';
	font-style: Regular;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/Jost-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Jost SemiBold - latin-ext_latin */
@font-face {
	font-family: 'Jost';
	font-style: Regular;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/Jost-SemiBold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/Jost-SemiBold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Jost SourceSerifPro - latin-ext_latin */
@font-face {
	font-family: 'SourceSerifPro';
	font-style: Regular;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/SourceSerifPro-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/SourceSerifPro-Regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Jost SourceSerifPro - latin-ext_latin */
@font-face {
	font-family: 'SourceSerifPro';
	font-style: Regular;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/SourceSerifPro-SemiBold.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/SourceSerifPro-SemiBold.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'LEMON MILK';
	src: url('RESOURCE/fonts/LEMONMILK-Medium.eot');
	src: url('RESOURCE/fonts/LEMONMILK-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/LEMONMILK-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/LEMONMILK-Medium.woff') format('woff'),
		url('RESOURCE/fonts/LEMONMILK-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/LEMONMILK-Medium.svg#LEMONMILK-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Serif Pro';
	src: url('RESOURCE/fonts/SourceSerifPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSerifPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSerifPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSerifPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSerifPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSerifPro-Regular.svg#SourceSerifPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'LEMON MILK';
	src: url('RESOURCE/fonts/LEMONMILK-Regular.eot');
	src: url('RESOURCE/fonts/LEMONMILK-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/LEMONMILK-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/LEMONMILK-Regular.woff') format('woff'),
		url('RESOURCE/fonts/LEMONMILK-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/LEMONMILK-Regular.svg#LEMONMILK-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Light.eot');
	src: url('RESOURCE/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Light.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}