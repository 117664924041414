.unit-image-gallery-v2 {

	.unit-list-section {
		.owl-item {
			position: relative;
			border-left: 5px solid var(--white);
			border-right: 5px solid var(--white);

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.6);
				content: ' ';
				transition: all 0.5s ease;
				z-index: 1;
			}

			&:hover {
				&:before {
					background: rgba(255, 255, 255, 0.8);
				}
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 1380px;
			margin: 0 auto;
			transform: translate(-50%, 0);

			.owl-prev,
			.owl-next {
				background: #ffffff;
				font-size: 24px;
				line-height: 40px;
				width: 40px;
				height: 40px;
				text-align: center;
				position: absolute;
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}

			.owl-prev {
				left: 15px;
			}

			.owl-next {
				right: 15px;
			}

			@media (max-width:992px) {
				/*display: none;*/
			}


			button {
				width: 60px;
				height: 60px;
				background-color: var(--black2) !important;
				position: absolute;
				left: 50px;
				font-size: 34px;

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: fontawesome;
						position: relative;
						left: 5px;
						top: 0px;
						font-size: 40px;
					}
				}


				&.owl-next {
					position: absolute;
					left: initial;

					span {

						&:before {
							content: "\f054";
							left: 5px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}

		.item {
			overflow: hidden;
			width: 100%;
			padding-bottom: 51.25%;

			@media (max-width:992px) {
				height: 250px;
			}


			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
			}
		}
	}

	.btn-picture {
		position: absolute;
		z-index: 10;
		padding-left: 50px;
		text-transform: inherit;
		background: rgb(0, 0, 0, 0.5);
		border: 2px solid #fff;
		color: #fff;
		font-size: var(--font-size-sm);
		border-radius: 5px;
		margin-top: -50px;
		letter-spacing: 2px;

		i {
			font-size: var(--font-size-main);
			position: absolute;
			margin-right: 6px;
			left: 10px;
			font-size: 16px;
		}
	}

}