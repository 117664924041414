.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
			padding-right: 125px;
		}

		#accordion4,
		#accordion2,
		#accordion1 {
			.shadow-bl {
				.card-header {
					padding: 0;
					background-color: #fff;
					border: none;

					button {
						line-height: 1;
						padding: 0;
						width: 100%;
						text-align: left;

						&:after {
							background-image: url(RESOURCE/img/icon-angle-down.svg);
							background-size: 16px;
							width: 17px;
							content: "";
							height: 10px;
							display: inline-block;
							position: absolute;
							right: 17px;
							background-repeat: no-repeat;
						}

						&[aria-expanded="true"] {
							&:after {
								transform: rotate(180deg);
							}

						}

						span {
							font-size: 14px;
							letter-spacing: 2px;
						}
					}
				}

				.card-body {
					padding: 0;

					.unit-properties-v1 .tick-li {
						margin: 0;
					}
				}
			}
		}


	}

	.right-col {

		padding: 0px 15px;

		.contact-bl {
			background-color: var(--color-primary);
			padding: 35px 10px;
			text-align: center;
			color: #555555;
			box-shadow: 0px 9px 20px #0000001A;
			line-height: 1.8;
			border-radius: 5px;

			a {
				text-decoration: underline;
				color: #555555;
			}
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}

		.unit-request-form-v3 {
			margin-top: 60px;
			position: relative;
			border-top: 1px solid var(--color-grey-light);
			padding-top: 40px;

			&:before {
				content: "oder";
				text-transform: uppercase;
				font-size: 10px;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);
				top: -12px;
				background-color: var(--color-white);
				padding: 5px 10px;
			}
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}