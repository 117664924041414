.unit-photos-v2 {
	.unitPhotos {
		margin: 0 -5px;

		.imgcols {
			width: 16.66%;
			position: relative;
			overflow: hidden;
			margin-bottom: 20px;
			float: left;
			padding: 0 10px;
			height: 132px;
			border-radius: 5px;
			overflow: hidden;

			@media (max-width:992px) {
				width: 20%;
				height: 85px;
			}

			@media (max-width:650px) {
				width: 33%;
				height: 100px;
			}



			&:before {
				content: "";
				background-color: rgba(255, 255, 255, 0);
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				visibility: hidden;
				transition: all .5s ease-in-out;
				opacity: 1;
				width: calc(100% - 10px);
				height: 100%;
				left: 5px;
			}

			&:hover {
				&:before {
					opacity: 1;
					visibility: visible;
					background-color: rgba(255, 255, 255, .3);
				}
			}



			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center center;
				object-position: center center;
				height: 100%;
				width: 100%;
				border-radius: 5px;

			}
		}
	}

}

.showLess {
	.unitPhotos {
		.imgcols {
			display: none;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10),
			&:nth-child(11),
			&:nth-child(12) {
				display: block;
			}
		}
	}

	&.showFull {
		.unitPhotos {
			.imgcols {
				display: block;

			}
		}
	}
}