.newsletter-section {
	.newsletter-post-bl {
		bottom: -70px;
		position: relative;
		z-index: 5;
		width: 100%;
		padding: 0 20px;

		.form-control {
			font-size: 12px;
			height: inherit;
			padding: 0 20px;
			margin-right: 2px;
			border-radius: 5px 0 0 5px;
			text-transform: uppercase;
			letter-spacing: 2px;
			background-color: #f7f7f7db;

			@media (max-width:767px) {
				padding: 10px;
				font-size: 13px;
			}
		}


		.newslet-bl {
			background-color: var(--color-primary);
			padding: 40px 60px;
			border-radius: 10px;
			max-width: 1024px;
			margin: 0 auto;
			text-align: center;
			color: var(--color-black);

			@media (max-width:767px) {
				font-size: 12px;
				padding: 20px;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.widt-cont-bl {
			max-width: 490px;
			margin: 0 auto;
			border-radius: 5px;
			padding: 0 !important;
			background-color: var(--color-white);

			.btn-secondary {
				padding: 9px 20px;
				border-radius: 0 5px 5px 0;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 11px;
				letter-spacing: 2px;

				@media (max-width:767px) {

					span {
						display: none;
					}

					width: 44px;
					padding: 12px 10px;

					&:before {
						content: "\f054";
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-size: 24px;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, 0);
						font-family: "Font Awesome 5 Free";
					}
				}
			}


		}
	}
}


.footer-v1 {
	text-transform: uppercase;
	position: relative;
	padding-top: 0;
	margin-top: 0px;

	.v-office-logo {
		margin-top: 50px;
	}

	.main-footer {
		padding-top: 0;
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}

		ul {
			li {
				font-family: var(--h3-font-family);
				text-transform: var(--h3-text-transform);
				color: var(--color-white);
				letter-spacing: 2px;
			}
		}

		.logo {
			width: 200px;
			height: auto;
			filter: brightness(0) invert(1);
			padding-bottom: 20px;
		}

		p {
			font-family: var(--h3-font-family);
			text-transform: var(--h3-text-transform);
			color: var(--color-white);
			letter-spacing: 2px;
			line-height: 32px;
			font-size: 14px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--color-white);
			letter-spacing: 2px;
		}

		.social-icons {

			li {


				a {

					font-family: var(--h3-font-family);
					text-transform: var(--h3-text-transform);
					color: var(--color-white);
					letter-spacing: 2px;
				}

				.fa {
					font-size: 20px;
					color: var(--color-white);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--color-white);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -70px;
			padding-right: 15px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);

		p {
			text-transform: var(--h3-text-transform);
			color: var(--color-white);
			font-family: var(--title-font-family-main);

			font-size: 13px;
			letter-spacing: 1.5px;
		}

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}