.spoerleben-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/spoerleben-inner-banner.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		position: relative;
		background-position: center;



		.page-title {
			position: absolute;
			top: 0;
			width: 100%;
			left: 0;
			z-index: 2;
			margin: 0;
			border-bottom: none;
			text-align: right;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		h1 {
			text-align: center;
			font-family: var(--hero-header-font-family);
			font-size: 70px;
			line-height: 98px;
			color: #fff;
			text-shadow: 0 1px 30px rgba(0, 0, 0, .5451);
			letter-spacing: 14px;
			font-weight: 600;
			margin-bottom: 0;
			line-height: normal;

			@media (min-width: 1500px) {
				font-size: 70px;
			}

			@media (max-width: 1140px) {
				font-size: 48px;
			}

			@media (max-width: 992px) {
				font-size: 2.8rem;
				line-height: 3rem;
			}

			@media (max-width: 576px) {
				font-size: 2.5rem;
				line-height: 2.8rem;
			}

			@media (max-height: 600px) {
				font-size: 2.5rem;
				line-height: 2.5rem;

			}
		}

	}

	.dream-destination {
		.left {}

		.right {
			padding-left: 70px;

			@media (max-width: 992px) {
				padding-left: 30px;
			}

			@media (max-width: 767px) {
				padding-left: 15px;
			}
		}
	}

	h2 {
		font-family: var(--font-family-main2);
		font-weight: 500;
		font-size: 44px;
		line-height: normal;

		@media (max-width: 1199px) {
			font-size: 34px;
		}

		@media (max-width: 992px) {
			font-size: 28px;
		}

		@media (max-width: 767px) {
			font-size: 24px;
		}

	}

	p {
		font-family: var(--font-family-main3);
		font-size: 18px;
		color: #837E7A;
	}


	.spo-section {
		background-image: url(RESOURCE/img/spo-banner.jpg);
		height: 920px;
		background-position: top;
		background-size: cover;
		position: relative;
		margin-top: 200px;

		.content-box {
			background: #fff;
			padding: 70px 30px 50px 70px;
			border-radius: 7px;
			box-shadow: 0 1px 30px rgba(0, 0, 0, .25);
			max-width: 1020px;
			width: 100%;
			margin: 0 auto;
			top: -200px;
			position: relative;

			@media (max-width: 767px) {
				padding: 30px 30px 30px 30px;
			}


			ul {
				text-align: left;
				margin-top: 80px;
				margin-bottom: 0;

				li {
					font-size: 16px;
					color: #837e7a;
					font-family: var(--font-family-main2);
					font-weight: 300;
					margin-bottom: 30px;
					background-image: url(RESOURCE/img/list-dot.png);
					background-repeat: no-repeat;
					background-position: 0 8px;
					padding-left: 30px;
					line-height: normal;

					@media (max-width: 767px) {
						font-size: 14px;
					}
				}
			}

		}
	}

	.pt-mob-25 {
		@media (max-width: 767px) {
			padding-top: 25px !important;
		}
	}

	.spoerleben-three-images {
		.row {
			margin: 0;

			.col-md-4 {
				padding: 0;

				img {
					width: 100%;
					height: 630px;
					object-fit: cover;

					@media (max-width: 1350px) {
						height: 400px;
					}

					@media (max-width: 1199px) {
						height: 300px;
					}
				}
			}
		}
	}
}