.navbar-v1.affix,
.navbar-v1 {
	background-color: var(--color-black);
	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	padding: 10px 0;


	@media (max-width:1200px) {
		position: absolute;

	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}



	.header-main {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header-logo {
			position: relative;
			z-index: 20;

			.logo {
				width: 180px;


				@media (max-width:1200px) {
					width: 180px;
				}

				@media (max-width:768px) {
					width: 110px;


				}
			}

		}

		.main-menu {
			float: right;

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;
					margin: 0 1.2vW;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
						margin: 0;
						padding: 0 1.2vW;
					}

					&:last-child {
						margin-right: 0;
					}

					&.bdr-bl {
						border-right: 2px solid var(--color-white);
						padding-right: 20px;
						margin-right: 0;
						margin-left: 2px;
					}


					@media (max-width: 1200px) {
						color: var(--color-white);
					}




					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 25px 0;
						line-height: 1;
						display: flex;
						font-weight: 400;
						text-transform: uppercase;
						font-family: var(--title-font-family-main);
						letter-spacing: 2px;


						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							text-decoration: none;
						}

						&.active {
							height: 5px;
							background-color: var(--color-primary);
							width: 100%;
							content: "";
							position: absolute;
							left: 0;
							bottom: 0;
						}

						i {
							font-size: 24px;
							position: relative;
							margin-left: 10px;
							top: -5px;
						}

						i.fa-heart {
							color: var(--color-primary);
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 12px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}



	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {


		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: 20px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.header-main {

			.header-logo {
				position: static;
				text-align: center;

			}

			.nav-row {
				min-height: 20px !important;
			}


		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {

		z-index: 5;
		/* background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(189, 189, 189, 0.2699930313922444) 24%, rgba(0, 0, 0, 0.5725140397956057) 100%);
		box-shadow: inherit; */
		background: transparent;
		border: none;
		box-shadow: none;

		&:before {
			content: " ";
			background: rgb(0, 0, 0);
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
			width: 100%;
			position: absolute;
			height: 290px;
			top: 0;
			z-index: -1;
			display: block !important;

			@media (max-width: 1199px) {
				height: 240px;
			}

			@media (max-width: 767px) {
				height: 160px;
			}
		}

		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						&.bdr-bl {
							border-right: 2px solid var(--color-white);
							padding-right: 20px;
							margin-right: 0;
							margin-left: 2px;
						}

						>a {
							color: var(--color-white);
							display: flex;

							&:hover {
								color: var(--main-nav-font-color-hover);
								text-decoration: none;
							}

						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.small-menu {
	display: flex;
	justify-content: flex-end;
	align-items: center;


	a {
		font-size: 16px;
		margin-left: 15px;
		color: var(--color-white);

		&.icon {
			font-size: 20px;
			margin-top: -10px;
			text-decoration: none;
		}
	}

}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}