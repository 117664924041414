.unit-card-v2 {
	box-shadow: 0px 9px 20px #0000001A;
	margin-bottom: 30px;
	border-radius: 7px;
	background-color: var(--white);

	.content {
		padding: 15px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;


		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 0;
			font-size: 17px;
		}

		.fa-arrows {
			font-size: 16px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.unit-title {
			font-size: 14px;
			letter-spacing: 2px;
			color: var(--color-secondary);
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;
			border-top: 1px solid rgba(0, 0, 0, .1);
			padding-top: 15px;

			.label {

				color: var(--color-grey-normal);
				border-radius: 5px;
				font-weight: 500;
				letter-spacing: 3px;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}

			.btn-link {
				color: var(--color-grey-normal);
				text-transform: uppercase;
				font-family: var(--title-font-family-main);
				letter-spacing: 1px;
				font-size: 12px;
				font-weight: 500;
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}
	}

	.description {
		p {
			font-size: 14px;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		justify-content: space-between;
		margin: 5px 0;

		.d-flex {
			align-items: baseline;
		}
	}


	.comodity {
		padding: 5px 10px 5px 5px;
		display: flex;
		align-items: baseline;

		.fa.fa-bed {
			padding-top: 1px;
		}

		i {
			margin-right: 7px;
		}
	}

	.comodities {
		.stars {
			color: var(--color-primary);
			font-size: 11px;

			.fa {
				padding: 0;
			}
		}
	}

	.image-wrap {
		position: relative;
		border-radius: 7px 7px 0 0;
		overflow: hidden;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}




		.icons-region {
			position: absolute;
			bottom: 14px;
			left: 10px;
			display: flex;
			align-items: center;
		}

		.rules {
			background-color: white;
			padding: 0;
			border-radius: 5px;
			color: var(--font-color-main);
			margin-right: 10px;
			display: flex;

			.fa {
				padding-top: 6px;
				padding-bottom: 6px;
				padding-right: 0;
				font-size: 14px;
				color: var(--color-secondary);
			}

			.fa:first-child {
				padding-left: 5px;
			}

			.fa:last-child {
				padding-right: 5px;
			}
		}

		.region-bl {
			background-color: var(--color-grey-light);
			color: var(--color-white);
			border-radius: 5px;
			padding: 4px 12px;
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-family: var(--title-font-family-main);
		}

		.favorite {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}

	.icons-region {
		i {
			font-size: 16px;

			font-style: normal;
			width: 28px;
			height: 28px;

			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			@media (max-width:360px) {
				font-size: 14px;
				width: 26px;
				height: 26px;
			}

			&.cross {
				&:after {
					width: 1.5px;
					height: 100%;
					position: absolute;
					color: var(--color-grey-dark);
					content: "";
					background-color: #747474;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	}
}