.themes-v1 {
	margin: 0;
	padding: 0 0 25px;

	.col-md-4 {
		padding: 0;
		overflow: hidden;
	}

	@media (max-width:767px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none;
		cursor: unset;
	}


	/* .img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/demo3-familie.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/demo3-strandkorb.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/demo3-hund.jpg); 
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/grid-segeln.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/grid-luftbild.jpg);
	} */

	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/genau-1.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/genau-2.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/genau-3.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;




		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
			margin: 0;
			letter-spacing: 2px;
			text-shadow: 0 1px 4px rgb(0 0 0 / 50%);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 400px;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(1);

		@media (max-width:992px) {
			height: 300px;
		}

		&:hover {
			transform: scale(1.1);
		}
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}