.sidebar-map {
	border-radius: 5px;
	overflow: hidden;
}

.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);
	box-shadow: 0px 9px 20px #0000001A;
	border-radius: 5px;

	.rating {
		color: var(--color-primary);
		font-size: 11px;
	}

	.ul-nopints {
		margin-bottom: 30px;
	}

	.unit-link {
		border-top: 1px solid var(--color-grey-light);
		padding: 20px 0 0;
		text-align: right;

		a {
			color: var(--color-grey-normal);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 500;
		}
	}

	.tags {

		background-color: var(--color-grey-light);

		color: var(--color-white);
		border-radius: 5px;
		padding: 4px 12px;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-family: var(--title-font-family-main);
	}

	.star-unit-name {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		margin: 5px 0;
	}

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;
			font-family: "SourceSerifPro", sans-serif;
			letter-spacing: 0;
			text-transform: capitalize;
			font-size: 14px;

			.fa.fa-users {
				background-image: url(RESOURCE/img/icon-user-friend-black.svg);
				background-size: contain;
				width: 30px;
				height: 17px;
				position: relative;
				top: 2px;
				background-position: 0;

				&:before {
					content: "" !important;
				}
			}

			.fa.fa-smoking-ban {
				background-image: url(RESOURCE/img/icon-material-smoke-free.svg);
				background-size: contain;
				width: 30px;
				height: 17px;
				position: relative;
				top: 2px;
				background-position: 0;

				&:before {
					content: "" !important;
				}
			}

			.fa.fa-bath {
				background-image: url(RESOURCE/img/icon-awesome-shower.svg);
				background-size: contain;
				width: 30px;
				height: 17px;
				position: relative;
				top: 2px;
				background-position: 0;

				&:before {
					content: "" !important;
				}
			}

			.fa {
				width: 30px;
				font-size: 16px;
			}

			.fa-stack {
				margin-left: -5px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}